import React from 'react';
import "./main.css";

class Main extends React.Component {
    render() {
        return (
            <main>
                投稿一覧

            </main>
        );
    }
}

export default Main;