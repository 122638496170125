import React from 'react';
import "./header.css";
class Header extends React.Component {
    render() {
        return (
            <div className='header'>
                <div className='header-logo'>
                    <img src='/sitelogo.png' />
                </div>
            </div>
        );
    }
}

export default Header;